// Category.js

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import axios from "axios";
import Shimmer from "./Shimmer";

const Category = (props) => {
  const [counteron, setcounteron] = useState(false);
  const [category, setcategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/jhalamand/api/api.php",
        {
          method: "getData",
          table: "categories",
          column: "status",
          value: "1",
          orderColumn: "sort_order",
          ordervalue: "Desc",
        },
        { headers: headers }
      );
      setcategory(response.data.data);
      console.log("aa12", response.data.data)
    } catch (error) {
      console.error("Error fetching category data:", error);
      // Handle the error, e.g., display an error message to the user.
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 11,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 4.2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 4.2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3.2,
        },
      },
    ],
  };



  const handleCategoryClick = (categorys) => {
    props.onCategrySelected(categorys.categoryId, categorys.name);
  };


  if (category.length === 0) {
    return <Shimmer />;
  }

  return (
    <ScrollTrigger onEnter={() => setcounteron(true)}>
      <div className="col-md-12 slider section" id="category">
        <div className="container">
          <Slider className="ourclientsvs my-3" {...settings}>
            {category.map((categorys) => (
              <div className="clientimg" onClick={() => handleCategoryClick(categorys)}>
                <div className="category d-flex flex-column align-items-center position-relative">
                  <img src={"https://bookmyqrcode.com/home_rental/" + categorys.image} alt="" />
                  <h4 className="position-absolute top-50 start-50 translate-middle sliderh4">
                    {counteron && (
                      <CountUp
                        start={0}
                        end={categorys.name === "All" ? categorys.all_count : categorys.category_count}
                        duration={1}
                        delay={0}
                      ></CountUp>
                    )}
                  </h4>
                </div>
                <div className="categoryname">
                  <h5 className="my-2">{categorys.name}</h5>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </ScrollTrigger>



  );
};

export default Category;
