import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import { Link } from "react-router-dom";
import axios from "axios";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const Header = () => {
  const [inventory, sethowmany] = useState([])
  const [bordercount, setbrokercount] = useState([])
  const [totalVisits, settotalvisits] = useState([])
  const [product, setproduct] = useState([])
  const [footerslider, setproduct2] = useState([])
  const [belowslider, setproduct3] = useState([])


  useEffect(() => {
    fetchData();
    Sliders();
  }, [])

  const fetchData = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getDataInventory",
      }
    );
    settotalvisits(data.data.ip_count)
    sethowmany(data.data.data.length);
    setbrokercount(data.data.broker_count)
    // setBrokerName()
    console.log("brokercount", data.data.broker_count)
  };


  const Sliders = async () => {
    try {
      const response = await axios.post("https://bookmyqrcode.com/jhalamand/api/api.php", {
        "method": "getData",
        "table": "sliders",
        "column": "status",
        "value": "1"
      });
  
      console.log("dataSlider", response.data.data);
  
      // Filter sliders with slider_type "home"
      const homeSliders = response.data.data.filter(group => group.slider_type === "home");
      const footer = response.data.data.filter(group => group.slider_type === "footer");
      const other = response.data.data.filter(group => group.slider_type === "belowfilter");
      
      setproduct(homeSliders);
      setproduct2(footer);
      setproduct3(other);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };
  


  return (
    <div
      className="container-fluid headerhead text-white p-0"
    >
      <div className="">
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {product.map((img, index) => (
              <div
                style={{ height: "450px" }}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <img
                class="sliderimg"
                  src={img.slider}
                
                  alt={`Slide ${index}`}
                />
              </div>
            ))}

<div className="header">
          <div className="nav container-fluid">
            <nav class="navbar navbar-expand-lg container-fluid">
              <div className="col-10">
                <div className="tooglerdiv">
                  <img
                    src="Assets/image/toogle.png"
                    className="navbar-toggler"
                    alt=""
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasDarkNavbar"
                  />
                </div>

                <div
                  class="offcanvas offcanvas-start  bg-dark"
                  tabindex="-1"
                  id="offcanvasDarkNavbar"
                  aria-labelledby="offcanvasDarkNavbarLabel"
                >
                  <div className="offcanvas-body ">
                    <div className="logos">
                      <div class="offcanvas-header ">
                        <h5
                          class="offcanvas-title"
                          id="offcanvasDarkNavbarLabel"

                        ></h5>
                        <svg
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-x-circle-fill "
                          viewBox="0 0 16 16"
                          id="headerclosebutton"

                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      </div>
                    </div>

                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                      <NavLink to={"/"}>
                        <li class="nav-item">
                          <a
                            class="nav-link active text-white"
                            aria-current="page"
                          >
                            Home
                          </a>
                        </li>
                      </NavLink>
                      <NavLink to={"/aboutus"}>
                        <li class="nav-item">
                          <a class="nav-link active text-white">About </a>
                        </li>
                      </NavLink>
                      <NavLink to={""}>
                        <li class="nav-item">
                          <a
                            class="nav-link active text-white"
                            aria-current="page"
                          >
                            Reach Us
                          </a>
                        </li>
                      </NavLink>
                    </ul>
                  </div>

                  <div className="d-flex col-md-12 iconsresponsive justify-content-center">
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-linkedin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                      </svg>
                    </div>

                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </div>

                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-twitter"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-2 d-flex buttondivs">
                <a href="/Loginpage" >
                  <div class="box " style={{ whiteSpace: "nowrap" }}>
                    <span>List Your Shop</span>
                    <i></i>
                  </div>
                </a>
              </div> */}
            </nav>
          </div>
        </div>
        <div className="container TopHeader">
          <div className="row  section topheaderow
        ">
            <div className="col-3 Clientslogo ">
              <Link to={"/"}>
                <div className="logo">
                  <img className="img-fluid" src="Assets/image/logo.png" alt="" />
                </div>
              </Link>
            </div>
            <div className="col-2 text-center Clients my-2">
              <h2 className="mb-1">{totalVisits}</h2>
              <h6 className="mb-1">Websites Visits</h6>
              <i></i>
            </div>
            <div className="col-2 text-center Clients my-2">
              <h2 className="mb-1">{inventory}</h2>
              <h6 className="mb-1">Inventory</h6>
              <i></i>
            </div>
            <div className="col-2 text-center Clients my-2">
              <h2 className="mb-1">{bordercount}</h2>
              <h6 className="mb-1">Channel Partner</h6>
              <i></i>
            </div>
          </div>
        </div>

        <div
        className="text-center col-md-12 d-flex flex-column align-items-center  onlinerental p-4  "
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="1300"
      >
        <h2 style={{ fontWeight: "650" }}>jhalamand.com</h2>
        <h5
          className=""
          style={{
            fontWeight: "550",
            fontSize: "20px",
            letterSpacing: "0.3px",
          }}
        >
       Yaha Sab Milega
        </h5>{" "}
      </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>



       
      </div>
      
      <div>


      </div>
    </div>
  );
};

export default Header;
