import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Category from "./Category";
import Form from "./Form";
import Footer from "./Footer";


const Homepage = () => {

  // Ref to track whether the effect has already run



  const [selectedId, setSelectedId] = useState({});

  // Function to reset selectedId
  const resetSelectedId = () => {
    setSelectedId({});
  };

  return (
    <div id="homepage">
      <div id="mobileresponsive">
        <Header />
      </div>
      <Category onCategrySelected={(id, name) => setSelectedId({ id, name })} />
      <Form selectedId={selectedId} resetSelectedId={resetSelectedId} />
      <Footer />


    </div>
  );
};

export default Homepage;
