
import { toast, ToastContainer } from "react-toastify";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';

const BrokerProfile = () => {

    const brokerId = useSelector(state => state.brokerId);

    const [name, setname] = useState([]);
    const [number, setnumber] = useState([]);
    const [Refanumber, setRefanumber] = useState([]);
    const [feraname, setferaname] = useState([]);
    const [Location, setLocation] = useState([]);
    const [rera_expiry, setrera_expiry] = useState([]);
    const [Profile, setProfile] = useState([]);

    console.log("brokerIds", brokerId)


    
    useEffect(() => {
        profile()
    }, [brokerId])

    const profile = async () => {
        try {
            const data = await axios.post(
                "https://bookmyqrcode.com/home_rental/api/api.php",
                {
                    method: "getData",
                    column: "brokerId",
                    value: `${brokerId}`,
                    table: "broker"
                }
            );
    
            setProfile(data?.data?.data);
            console.log("setProfile",data?.data?.data)
    
            if (data?.data?.data && data.data.data.length > 0) {
                const profileData = data?.data?.data[0];
                setname(profileData.name);
                setnumber(profileData.number);
                setRefanumber(profileData.rera_number);
                setferaname(profileData.firm_name);
                setLocation(profileData.location);
                setrera_expiry(profileData.rera_expiry);
            }
        } catch (error) {
            console.error("Error fetching profile data:", error);
        }
        
    };
    
    const BrokerDatasend = async (e) => {


         setname("")
setRefanumber("")
setLocation("")
setferaname("")
setrera_expiry("")
        e.preventDefault(); // Prevent the default form submission behavior

        if (name.length === 0) {
            toast.error("Enter Name", {
                position: "bottom-right",
                autoClose: 4988,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return; // Exit the function if any field is empty
        }


        if (feraname.length === 0) {
            toast.error("Enter Firm Name", {
                position: "bottom-right",
                autoClose: 4988,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return; // Exit the function if any field is empty
        }
        if (Location.length === 0) {
            toast.error("Enter Location", {
                position: "bottom-right",
                autoClose: 4988,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return; // Exit the function if any field is empty
        }
        if (rera_expiry.length === 0) {
            toast.error("Enter Rera Expiry Date", {
                position: "bottom-right",
                autoClose: 4988,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return; // Exit the function if any field is empty
        }
        if (Refanumber.length === 0) {
            toast.error("Enter Rera Number", {
                position: "bottom-right",
                autoClose: 4988,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return; // Exit the function if any field is empty
        }
    
        else {
            const request = {
                method: "setData",
                column: "brokerId",
                value: `${brokerId}`,
                table: "broker",
                data:

                {
                    name: `${name}`,
                    firm_name: `${feraname}`,
                    rera_number: `${Refanumber}`,
                    number: `${number}`,
                    location: `${Location}`,
                    rera_expiry: `${rera_expiry}`,

                }
            }


            console.log("requestasdasdaa", request);
            const data = await axios.post(
                "https://bookmyqrcode.com/home_rental/api/api.php",
                {
                    method: "setData",
                    column: "brokerId",
                    value: `${brokerId}`,
                    table: "broker",
                    data:
                    {
                        name: `${name}`,
                        firm_name: `${feraname}`,
                        rera_number: `${Refanumber}`,
                        number: `${number}`,
                        location: `${Location}`,
                        rera_expiry: `${rera_expiry}`,
                    }
                }
            );




            console.log("succ", data.data.status)
            console.log("data", data);
            if (data.data.status === "Success") {
                toast.success(" Success", {
                    position: "bottom-right",
                    autoClose: 4988,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                alert(false);
            }
        }
    };

    return (
        <div className="">
                  <div class="modal-header">
                            <h1 class="modal-title fs-5 " style={{ fontWeight: "600" }} id="staticBackdropLabel">Edit Your Profile</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
            {Profile && Profile.length > 0 ? (

                Profile.map((profile) => (
                    <form class="row g-3 col-md-12 container p-5 pt-2 ">
                  
                        <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Name</label>
                            <input
                                type="text"
                                placeholder={profile.name}
                                class="form-control"
                                id="exampleInputPassword1"
                                value={name}
                                onChange={(e) => {
                                    setname(e.target.value);
                                }}
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Number</label>
                            <input
                                type="number"
                                class="form-control"
                                placeholder={profile.number}
                                id="exampleInputPassword1"
                                value={number}
                                readOnly
                            />
                        </div>
                        <div class="col-12">
                            <label for="inputAddress" class="form-label">Address</label>
                            <input type="text" class="form-control" id="inputAddress" placeholder={profile.location} value={Location}
                                onChange={(a) => {
                                    setLocation(a.target.value);
                                }} />
                        </div>

                        <div class="col-md-6">
                            <label for="inputCity" class="form-label">Rera Number</label>
                            <input type="number" class="form-control" placeholder={profile.rera_number} id="inputCity/" value={Refanumber} 
                               readOnly/> 
                        </div>

                        <div class="col-md-6">
                            <label for="inputZip" class="form-label">Firm Name</label>
                            <input type="text" class="form-control" placeholder={profile.firm_name} id="inputZip" value={feraname}
                                onChange={(fi) => {
                                    setferaname(fi.target.value);
                                }} />
                        </div>
                        <div class="col-md-6">
                            <label for="inputZip" class="form-label">Rera Expiry</label>
                            <input type="text" class="form-control" placeholder={profile.rera_expiry} id="inputZip" value={rera_expiry}
                                onChange={(ex) => {
                                    setrera_expiry(ex.target.value);
                                }} />
                        </div>


                        <div className="buttonss d-flex justify-content-center">
                            <button
                                onClick={BrokerDatasend}>
                                <p className="mb-0" >Post Now</p>
                                <div>
                                </div>
                            </button>
                            <ToastContainer />
                        </div>
                    </form>
                ))
            ) : (
                <div className="text-center p-4">Please wait...</div>
             )}
        </div>
    )
}

export default BrokerProfile
