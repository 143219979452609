import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { initMDB, Tab } from "mdb-ui-kit";
import Postcontent from './Postcontent';
import { useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import { TextField } from '@mui/material';
import BrokerProfile from './BrokerProfile';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Broker = () => {


  const [Data, setData] = useState([])
  const [Inventroy, SetInventroy] = useState([])
  const [BrokerData, setBrokerData] = useState([])
  const [loading, setLoading] = useState(true);
  const [Profile, setProfile] = useState([]);
  const [InvenID, SetInvenID] = useState([]);
  const [StatusiD, Setstatus] = useState([]);
  const [name, setname] = useState([]);
  const [number, setnumber] = useState([]);
  const navigate = useNavigate(); // Get access to the navigate function
  const dispatch = useDispatch();

  useEffect(() => {
    fetchdatas()
    fetchData()
    Brokerdata()
    profile()
  }, [])




  const handleToggleOffcanvas = () => {
    const broker2Div = document.querySelector('.broker2Div');
    broker2Div.classList.toggle('hidden');
  };

  const location = useLocation();
  const brokerId = location.state?.brokerId || Cookies.get('brokerId');


  useEffect(() => {
    if (!brokerId) {
      // If there's no brokerId, redirect to the login page or handle it as needed
      // Example: history.push("/login");
    }
    dispatch({ type: 'SET_BROKER_ID', payload: brokerId });


    // Log the value of brokerId after setting the cookie
    console.log("BrokerId after setting cookie:", Cookies.get('brokerId'));

    initMDB({ Tab });
  }, [brokerId]);



  const Status = async (inventroyId, Status) => {

    const statusid = Status === "0" ? "1 " : "0"

    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",

      {
        method: "setData",
        table: "inventroy",
        column: "inventroyId",
        value: `${inventroyId}`,
        data: {
          Status: `${statusid}`
        }
      }
    )

    Setstatus(Status);
    SetInvenID(inventroyId);
    console.log("Statusssssd", statusid)
    console.log("Statusss", inventroyId)
    fetchData();

  }

  useEffect(() => {
    BrokerSend()
  }, [StatusiD, InvenID])


  const Brokerdata = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        column: "brokerId",
        value: `${brokerId}`,
        table: "post_rent"
      }
    )

    setBrokerData(data.data.data)
    console.log("Brokerdatadsdsd", data)
  }

  const fetchdatas = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        column: "brokerId",
        value: `${brokerId}`,
        table: "enquiry",
      }
    );
    console.log("enquirydatass", data.data);
    setData(data.data.data)
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true before making the request

    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getDataInventoryBroker",
        brokerId: `${brokerId}`
      }
    );

    SetInventroy(data?.data?.data);
    setLoading(false); // Set loading to false after the request is completed

    console.log("getDataInventoryBroker", data)
  };
  const profile = async () => {

    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {

        method: "getData",
        column: "brokerId",
        value: `${brokerId}`,
        table: "broker"

      }
    );

    setProfile(data?.data?.data);
    console.log("sadfasdasasd", data?.data?.data)
  };

  const BrokerSend = async () => {

    const statusName = StatusiD === "0" ? "Active" : "InActive"

    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "setData",
        table: "brokerActivity",
        data: {
          brokerId: `${brokerId}`,
          InventoryId: `${InvenID}`,
          StatusDone: `${statusName}`
        }
      }
    );

    console.log("BrokerSend", data.config.data)
  };

  const Logout = () => {
    // Clear cookies
    Cookies.remove('brokerId');

    // Navigate to login page using useNavigate
    navigate('/');
  };

  // ... other code ...




  return (
    <div className='d-flex brokers' >
      <div style={{ display: loading ? "flex" : "none", height: "100vh", justifyContent: "center", alignItems: "center", position: "fixed", backgroundColor: "#0000005c", zIndex: "99999" }} className='col-md-12 container-fluid'>
        <iframe class="animationBrokerdiv" src="https://lottie.host/embed/c36be7e4-5bf2-4d21-865b-1846d449d85b/lcNUChhViD.json"></iframe>
      </div>

      <div className="col-3 brokerDIV">
        <div class="offcanvas offcanvas-start  show" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrollingss" aria-labelledby="offcanvasScrollingLabelss">
          <div className='col-md-12 d-flex  align-items-center justify-content-between p-3'>
<a href="/">            <img src="Assets/image/logo.png" style={{ maxHeight: "130px" }} className='img-fluid ' alt="" />
</a>            <div >
              <img src="Assets/image/drop-down-menu.png" onClick={handleToggleOffcanvas} type="button" className='broker2Divimg hiddentoggle' data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrollingss" aria-controls="offcanvasScrollingss" aria-label="Close" alt="" />
            </div>

          </div>

          <ul class="nav nav-pills mb-3  brokernav" id="ex1" role="tablist">

          <a
              data-mdb-pill-init
              class="nav-link my-2 active"
              id="ex1-tab-4"
              href="#ex1-pills-4"
              role="tab"
              aria-controls="ex1-pills-4"
              aria-selected="true"
            >
              <div className='px-2 my-2 d-flex align-items-center'>
                <img src="Assets/image/man.png" style={{ height: "28px" }} alt="" />
                <p className='mx-3 mb-1 text-broker' style={{ color: "#2c323f" }}>Profile</p>
                <img src="Assets/image/arrow.png" className='mt-1' alt="" />
              </div>
            </a>
        
            <a
              data-mdb-pill-init
              class="nav-link my-2"
              id="ex1-tab-3"
              href="#ex1-pills-3"
              role="tab"
              aria-controls="ex1-pills-3"
              aria-selected="true"
            >
              <div className='px-2 my-2 d-flex align-items-center'>
                <img src="Assets/image/bar-chart.png" style={{ height: "25px" }} alt="" />
                <p className='mx-3 mb-1 text-broker' style={{ color: "#2c323f" }}>Your Inventorys</p>
                <img src="Assets/image/arrow.png" className='mt-1' alt="" />
              </div>
            </a>

            <a
              data-mdb-pill-init
              class="nav-link "
              id="ex1-tab-1"
              href="#ex1-pills-1"
              role="tab"
              aria-controls="ex1-pills-1"
              aria-selected="true"
            >
              <div className='px-2 my-2 d-flex align-items-center'>
                <img src="Assets/image/house.png" style={{ height: "25px" }} alt="" />
                <p className='mx-3 mb-1 text-broker' style={{ color: "#2c323f" }}>Live Inventory</p>
                <img src="Assets/image/arrow.png" className=' mt-1' alt="" />
              </div>
            </a>
            <a
              data-mdb-pill-init
              class="nav-link my-2"
              id="ex1-tab-2"
              href="#ex1-pills-2"
              role="tab"
              aria-controls="ex1-pills-2"
              aria-selected="true"
            >
              <div className='px-2 my-2 d-flex align-items-center'>
                <img src="Assets/image/search.png" style={{ height: "28px" }} alt="" />
                <p className='mx-3 mb-1 text-broker' style={{ color: "#2c323f" }}>Enquiry</p>
                <img src="Assets/image/arrow.png" className=' mt-1' alt="" />
              </div>
            </a>
           
         

          </ul>
          <div style={{ height: "40vh " }} className='d-flex align-items-end justify-content-center'>
            <div className='buttonss'>
              <button style={{backgroundColor:"#F72727"}} onClick={Logout}>Log Out</button>
            </div>
          </div>
        </div>
      </div>
      <div className=" broker2Div" >
        <div className='brokertoggle p-3  col-md-12 d-flex justify-content-between align-items-center'>
          <img style={{ minHeight: "40px" }} src="Assets/image/drop-down-menu.png" onClick={handleToggleOffcanvas} type="button" className='broker2Divimg' data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrollingss" aria-controls="offcanvasScrollingss" aria-label="Close" alt="" />
          {/* ///////////////// */}
          {/* ///////////////// */}
          <div data-bs-toggle="modal" data-bs-target="#staticBackdrop">

<div className=' '>
<button class="button-55" role="button">
  <span class="text">Post Your Inventory</span>
</button>
</div>          </div>
          {/* ///////////////// */}
          {/* ///////////////// */}


          {/* /////////////////////// */}
          {/* /////////////////////// */}

          <div class="modal fade modal-xl" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modalBrokerpage">
              <div class="modal-content">

                <div class="modal-body">
                  <Postcontent />
                </div>

              </div>
            </div>
          </div>
          {/* /////////////////////// */}
          {/* /////////////////////// */}
        </div>
        <div className='brokertoggle2 d-flex justify-content-center'>

          <div className='brokertoggle2iiner tab-content col-md-12 ' id="ex1-content" style={{ borderRadius: "10px" }}>
            {/* first tab */}
            {/* first tab */}
            <div
              class="tab-pane fade"
              id="ex1-pills-2"
              role="tabpanel"
              aria-labelledby="ex1-tab-2"
            >
              <h5 style={{ fontWeight: "650", color: "#4D1A05" }} className='p-3 px-1'>Enquiry</h5>
              <div class="table-responsive">
                <table class="table ">
                  <thead >
                    <tr className='d-flex' >
                      <th scope="col" className=' col-3 col-md-1' >EnquiryId</th>
                      <th scope="col" className='col-3 col-md-3'>InventroyId</th>
                      <th scope="col" className='col-4 col-md-2'>Number</th>
                      <th scope="col" className='col-md-3 col-3'>Shifting date</th>
                      <th scope="col" className='col-5  col-md-3'>Created date</th>
                    </tr>
                  </thead>
                  <tbody>

                    {Data && Data.length > 0 && (

                      Data.map((Enquiry) => (
                        <tr className='d-flex'>
                          <td scope="col " className=' col-3 col-md-1'>{Enquiry.enquiryId}</td>
                          <td scope="col " className=' col-3 col-md-3'>{Enquiry.inventroyId}</td>
                          <td scope="col" className='col-4 col-md-2' >{Enquiry.number}</td>
                          <td scope="col" className='col-md-3 col-3'>{Enquiry.shifting_date}</td>
                          <td scope="col" className='col-5  col-md-3'>{Enquiry.created_date}</td>
                        </tr>))

                    )}
                  </tbody>
                </table>
              </div>
            </div>


            {/* first tab */}
            {/* first tab */}
            <div class="tab-pane fade    " id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">

              <h5 style={{ fontWeight: "650", color: "#4D1A05" }} className='p-3 px-0 text-start col-md-12'>Live Inventory</h5>

              <div class="table-responsive">
                <table class="table ">
                  <thead >
                    <tr className='d-flex text-center' >
                      <th scope="col" className=' col-2 col-md-1' >Inventory Id</th>
                      <th scope="col" className=' col-2 col-md-1' >Title</th>
                      <th scope="col" className='col-2 col-md-1'>Intrested</th>
                      <th scope="col" className='col-2 col-md-1'>Category Type</th>
                      <th scope="col" className='col-md-1 col-2'>City</th>
                      <th scope="col" className='col-3  col-md-2'>Area</th>
                      <th scope="col" className='col-2  col-md-1'>Date</th>
                      <th scope="col" className='col-2  col-md-1'>Rent</th>
                      <th scope="col" className='col-6  col-md-2'>Amenities</th>
                      <th scope="col" className='col-2  col-md-1'>Status</th>
                    </tr>
                  </thead>
                  <tbody>

                    {Inventroy && Inventroy.length > 0 && (

                      Inventroy.map((Inventroy) => (
                        <tr className='d-flex'>
                          <td scope="col " className=' col-2 col-md-1'>{Inventroy.inventroyId}</td>
                          <td scope="col " className=' col-2 col-md-1'>{Inventroy.title}</td>
                          <td scope="col " className=' col-2 col-md-1'>{Inventroy.enquiryCount}</td>
                          <td scope="col" className='col-2 col-md-1' >{Inventroy.category_name}</td>
                          <td scope="col" className='col-md-1 col-2'>{Inventroy.city_name}</td>
                          <td scope="col" className='col-3  col-md-2'>{Inventroy.area_name}</td>
                          <td scope="col" className='col-2  col-md-1'>{Inventroy.avalible_date}</td>
                          <td scope="col" className='col-2  col-md-1'>{Inventroy.rent}</td>
                          <td scope="col" className='col-6  col-md-2'>{Inventroy.admin_remark}</td>
                          <td scope="col " className=' col-2 col-md-1'><button onClick={() => Status(Inventroy.inventroyId, Inventroy.status, Inventroy.inventroyId)} className={`btn ${Inventroy.status === "0" ? 'btn-danger' : 'btn-success'}`} >
                            {Inventroy.status === "0" ? "InActive" : "Active"}</button></td>
                        </tr>
                      ))

                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="ex1-pills-3"
              role="tabpanel"
              aria-labelledby="ex1-tab-3"
            >
              <h5 style={{ fontWeight: "650", color: "#4D1A05" }} className='p-3 px-1'>Your Inventorys

              </h5>
              <div class="table-responsive">
                <table class="table ">
                  <thead >
                    <tr className='d-flex' >
                      <th scope="col" className=' col-3 col-md-1' >PostId</th>
                      <th scope="col" className=' col-3 col-md-2' >Name</th>
                      <th scope="col" className='col-4 col-md-1'>Number</th>
                      <th scope="col" className='col-3  col-md-1'>Category</th>
                      <th scope="col" className='col-3 col-md-2'>Address</th>
                      <th scope="col" className='col-md-1 col-2'>Zip code</th>
                      <th scope="col" className='col-2  col-md-1'>Rent</th>
                      <th scope="col" className='col-3  col-md-2'>Created date</th>
                      <th scope="col" className='col-2  col-md-1'>Status</th>

                    </tr>
                  </thead>
                  <tbody>

                    {BrokerData && BrokerData.length > 0 && (
                      BrokerData.map((BrokerData) => (
                        <tr className='d-flex text-center'>
                          <td scope="col " className=' col-3 col-md-1'>{BrokerData.postId}</td>
                          <td scope="col " className=' col-3 col-md-2'>{BrokerData.name}</td>
                          <td scope="col" className='col-4 col-md-1' >{BrokerData.number}</td>
                          <td scope="col " className=' col-3 col-md-1'>{BrokerData.categoryId}</td>
                          <td scope="col " className=' col-3 col-md-2'>{BrokerData.address}</td>
                          <td scope="col" className='col-md-1 col-2'>{BrokerData.zip_code}</td>
                          
                          <td scope="col" className='col-md-1 col-2'>{BrokerData.rent}</td>
                          <td scope="col " className=' col-3 col-md-2'>{BrokerData.create_date}</td>

                          <td scope="col " className=' col-2 col-md-1'><button className=' btn btn-success'>
                            {BrokerData.adminStatus}</button></td>
                        </tr>))
                    )}

                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="tab-pane fade show active  d-flex justify-content-center brokerpage"
              id="ex1-pills-4"
              role="tabpanel"
              aria-labelledby="ex1-tab-4"
            >
              {Profile && Profile.length > 0 && (

                Profile.map((profile) => (
                  <div className='col-md-12 d-flex flex-column justify-content-center align-items-center Brokerpage p-4 px-1' >

                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center Profile">

                      <div className='d-flex flex-column justify-content-center align-items-center '>
                        <img style={{ minHeight: "95px" }} src="Assets/image/Personlogin.png" className='p-2' alt="" />
                        <h4 style={{ fontWeight: "600" }}>The {profile.name}</h4>
                        <p style={{ fontWeight: "550" }}>Your ID : {profile.brokerId}</p>
                      </div>

                      <div className='col-md-12 d-flex justify-content-center imgprofile mt-4' style={{ minWidth: "100%" }}>
                        <div className="row justify-content-center align-items-center">
                          <div className='d-flex col-md-5 flex-column justify-content-center align-items-center  text-justify mt-4 profiledata '>
                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3" style={{ width: "100%" }}><img src="Assets/image/phone-call.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>Number : <strong className='mx-1'>{profile.number}</strong></p></div>
                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3" style={{ width: "100%" }} ><img src="Assets/image/location.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>Location : <strong className='mx-1'>{profile.location}  </strong></p></div>
                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3" style={{ width: "100%" }}><img src="Assets/image/firmname.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>FirmName : <strong className='mx-1'>{profile.firm_name}</strong></p></div>
                          </div>
                          <div className='d-flex col-md-5 flex-column justify-content-center align-items-center text-justify mt-4 profiledata'>
                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3 " style={{ width: "100%" }}><img src="Assets/image/contract.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>Rera No:<strong className='mx-1'>{profile.rera_number}</strong></p></div>
                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3" style={{ width: "100%" }}><img src="Assets/image/expiry-date.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>Rera Expiry : <strong className='mx-1'>{profile.rera_expiry}</strong></p></div>

                            <div className="col-md-12 d-flex align-items-center justify-content-start p-3" style={{ width: "100%" }}><img src="Assets/image/calendar.png" className='p-2' alt="" />
                              <p className='pb-0 mb-0' style={{ fontWeight: "550" }}>Created Date : <strong className='mx-1'>{profile.created_date}</strong></p></div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex justify-content-center align-items-center p-2 my-0 mb-2 " data-bs-toggle="modal" data-bs-target="#exampleModal"   >
                      <button class="button-55" role="button">
  <span class="text mx-3">Edit Profile</span>
  <img style={{ maxHeight: "25px",color:"white" }} className='mx-0 my-0' src="Assets/image/plus.png" alt="" />

</button>
                      </div>

                    </div>
                  </div>
                ))

              )}

            </div>

            <div class="modal fade modal-xl" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog  modal-dialog-centered">
                <div class="modal-content p-1">
                  <BrokerProfile />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Broker
