import React from 'react'

const loader = () => {
  return (
    <div  className='d-flex justify-content-center' >
              <div class="col-md-12 " id="mobilediv">
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <h3 className="text-start Heading"></h3>
            </div>
            <div className="col-4">
              {" "}

            </div>
          </div>

          <div className="d-flex  row justify-content-start  col-12 videobar">
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
          </div>


        </div>
    </div>
  )
}

export default loader
