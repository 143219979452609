import React from "react";
import { useEffect } from "react";
import Header from "./Header";
import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from "js-cookie";  // Import the js-cookie library


import axios from "axios";
const Loginpage = (props) => {
  const [otp, setOtp] = useState([]);
  const [Login, setLogin] = useState("Login");
  const [Sign, setSign] = useState("Sign Up");
  const [user, setuser] = useState("Already a user");
  const [showLootie2, setShowLootie2] = useState(false);
  const [number, setnumber] = useState("");
  const [shownumber, setshownumber] = useState(true);
  const [showotp, setshowotp] = useState(false);
  const [loader, setloader] = useState(false);
  const [otpNUmber, setotpNumber] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let lastdigitofOtp = otp[5];

  console.log("lastdigitofOtp", lastdigitofOtp);

  
  useEffect(() => {
    // Check for the existence of brokerId in cookies when component mounts
    const storedBrokerId = Cookies.get('brokerId');

    if (storedBrokerId) {
      // Navigate to the Broker page if brokerId exists in cookies
      navigate("/Broker", { state: { brokerId: storedBrokerId } });
    }

    // Clear cookies when Loginpage mounts
   
  }, []);

  useEffect(() => {
    window.otpless = (otplessUser) => {
      console.log("otplessUser", otplessUser);
      setotpNumber(otplessUser.waNumber);
    };
  }, []);
  

  useEffect(() => {
    if (otpNUmber != "") {
      Channelpartner();
    }
  }, [otpNUmber]);

  const Channelpartner = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",

      {
        method: "brokerLogin",
        mobileNumber: `${otpNUmber}`,
      }
    );

    console.log("Channelpartner", data.data.data.brokerId);


    if (data.data.status === "Success") {
      // Use navigate to navigate to the "/Broker" page;
      
      const brokerId = data.data.data.brokerId;

      // Set the brokerId in the state
      dispatch({ type: 'SET_BROKER_ID', payload: brokerId });
      navigate("/Broker", { state: { brokerId } })

      Cookies.set('brokerId', brokerId, { expires: 7 }); // Expires in 7 days

      // Log the value of brokerId after setting the cookie
   console.log("BrokerId after setting cookie:", Cookies.get('brokerId'));

    }
  }

  
  return (
    <div className="loginpage d-flex align-items-center justify-content-center p-4">
      <div id="otpless-login-page"></div>
    </div>
  );
};

export default Loginpage;
