import React, { useState, useEffect, useRef } from "react";
import Postcontent from './Postcontent'
import Header from './Header'
import Footer from './Footer'
const Post = () => {

  // Ref to track whether the effect has already run


  return (
   <>
    <Header />
<Postcontent  />

<Footer/>
   </>
  )
}

export default Post
