import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const Details = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [minrent, setminrent] = useState("400")
  const [maxrent, setmaxrent] = useState("10000")
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    Firmproduct()
  }, [])

  console.log("as", location.state)

  const Firmproduct = async () => {

    const data = await axios.post("https://bookmyqrcode.com/jhalamand/api/api.php",
      {
        "method": "getData",
        "table": "inventroy",
        "column": "firmId,status",
        "value": `${location.state.user.firmId} ,1`
      }
    )
    console.log("data", data)
    setProducts(data.data.data)
  }


  useEffect(() => {
    if (products.length > 0) {


      const rents = products.map(items => items.rent);

      const maxAmount = Math.max(...rents);
      const minAmount = Math.min(...rents);

      setminrent(minAmount);
      setmaxrent(maxAmount);

      setValue([minAmount, maxAmount]);
    }

  }, [products]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="col-md-12 p-0 px-0 detailspage d-flex justify-content-center flex-column align-items-center">
      <div style={{ height: "300px", width: "100%" }} className=' position-relative'>
        <img style={{ height: "300px", width: "100%", objectFit: "cover" }} className='img-fluid' src={"https://bookmyqrcode.com/jhalamand/" + location.state.user.cover_image} alt="" />
        <h2 className="position-absolute top-50 start-50 translate-middle" style={{ zIndex: 1, color: "white" }}>{location.state.user.name}</h2>
      </div>

      <div className='d-flex flex-column align-items-center  justify-content-center col-md-10'>
        <div className="row col-md-12">
          <div className="col-md-3 my-5  " id="">
            <div className="form col-md-12  ">
              <div id="">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label p-2"
                >
                  Fillter as your  Budget
                </label>
                <div className="City">
                  <Box className="container    ">
                    <Slider
                      className="roodt"
                      getAriaLabel={() => "Temperature range"}

                      // getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      value={value}
                      onChange={handleChange}
                      Slider
                      // onChange={handleChange}
                      step={40}
                      marks
                      min={parseFloat(minrent)}
                      max={parseFloat(maxrent)}
                    />
                  </Box>
                  <div
                    className="container-fluid d-flex justify-content-between maxheight"
                    style={{ maxHeight: "15px" }}
                  >
                    <div>
                      <p>₹ {value[0]}</p>
                    </div>
                    <div>
                      <p>₹ {value[1]}</p>
                    </div>{" "}
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center my-4">
                  <div className="buttonss d-flex">
                    <button >Search</button>
                  </div>{" "}
                </div>

              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center col-md-8 ' >
            <div className="row my-4 justify-content-center">
              {products && products.length > 0 && products.map((items) => (
                <div class="card detailsDiv" style={{ width: "15.5rem", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                  <div className='p-0'>
                    <img src={items.image} class="card-img-top imageofdetails p-4 " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title " style={{ fontSize: "17px" }}>{items.title}</h5>
                    <p class="card-text " style={{ fontSize: "14px", color: "grey" }}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <p style={{ fontWeight: "600" }}> ₹ {items.rent}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default Details;
