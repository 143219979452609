


  import React, { useEffect, useState, useRef } from 'react';
  import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
  import axios from 'axios';
  
  const MapContainer = ({ google }) => {
    const [Data, setdata] = useState([]);
    const mapRef = useRef(null);
    const [userLocation, setUserLocation] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const offcanvasRef = useRef(null);
    const [directions, setDirections] = useState(null);
    const [TravelTime, settravelTime] = useState(null);
  
    useEffect(() => {
      Firms();
      getUserLocation();
    }, []);

    useEffect(() =>{
      fitMapBounds();

    },[Data])
  
    const Firms = async () => {
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "firms"
        }
      );
      setdata(data.data.data);
      console.log("datadatas", data.data.data);
    }
  
    useEffect(() => {
      // Log the selectedLocation whenever it changes
      console.log("selectedLocation", selectedLocation);
      if (selectedLocation) {
        calculateAndDisplayRoute();
      }
    }, [selectedLocation]);
  
    const fitMapBounds = () => {
      const bounds = new google.maps.LatLngBounds();
      Data.forEach((location) => {
        bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
                console.log("location.latitude",location.latitude)

      });
  
      const mapInstance = mapRef.current.map;
      mapInstance.fitBounds(bounds);
    };
  
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setUserLocation(location);
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    const openOffcanvas = () => {
      // Use Bootstrap's offcanvas show method
      const offcanvasInstance = new window.bootstrap.Offcanvas(offcanvasRef.current);
      offcanvasInstance.show();
    };
  
    const calculateAndDisplayRoute = () => {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
  
      const origin = new google.maps.LatLng(userLocation.lat, userLocation.lng);
      const destination = new google.maps.LatLng(selectedLocation.latitude, selectedLocation.longitude);
  
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(response);
            setDirections(response);
            console.log("response", response);
  
            const travelTime = response.routes[0].legs[0].duration.text;
            console.log("Travel Time:", travelTime);
  
            settravelTime(travelTime);
          } else {
            console.error('Directions request failed due to ' + status);
          }
        }
      );
    };
  


const style = {
  width: '50%',
  height: '50%'
}
    return (
      <div>
        <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel"
          ref={offcanvasRef}>
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body p-0">
            {selectedLocation ? (
              <div >
                <div style={{ height: "250px", maxWidth: "100%" }} className='mb-2' >
                  <img style={{ height: "250px", maxWidth: "100%" }} src="https://img.freepik.com/free-photo/luxury-house-real-estate-sale-property-generative-ai_169016-29361.jpg" alt="" />
                </div>
  
                {directions && (
                  <div className='p-3'>
                    <p>{`Selected Location: ${selectedLocation.title}`}</p>
                    <p>{`Selected Location: ${TravelTime}`}</p>
                    <p>{`Distance: ${directions.routes[0].legs[0].distance.text}`}</p>
                    <div ref={mapRef} style={{ width: '100%', height: '500px' }} />
                  </div>
                )}
              </div>
            ) : (
              <p>Try scrolling the rest of the page to see this option in action.</p>
            )}
          </div>
        </div>
        <Map
          google={google}
          ref={mapRef}
          style={style}

        >
          {Data.map((location) => (
            <Marker
              key={location.id}
              title={location.title}
              position={{ lat: location.latitude, lng: location.longitude }}
              onClick={() => {
                setSelectedLocation(location);
                openOffcanvas(); // Open offcanvas on marker click
              }}
              icon={{
                url: 'Assets/image/pin.png',
                anchor: new google.maps.Point(20, 40),
                scaledSize: new google.maps.Size(30, 30),
              }}
            />
          ))}
          {userLocation && (
            <Marker
              title="Your Location"
              position={userLocation}
              icon={{
                url: 'Assets/image/location.png',
                anchor: new google.maps.Point(50, 40),
                scaledSize: new google.maps.Size(50, 50),
              }}
            />
          )}
          {directions && (
            <Polyline
              path={directions.routes[0].overview_path}
              options={{
                strokeColor: 'darkblue',
                strokeWeight: 6,
                strokeOpacity: 0.7,
              }}
            />
          )}
        </Map>
      </div>
    );
  };
  
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyCeVmtHvHA8qXWLzXguVwlw2JNn6W07U8A',
  })(MapContainer);
  