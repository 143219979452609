import React from "react";

const Aboutusimage = () => {
  return (
    <div>
      <div className="imgaboutus text-center text-white d-flex flex-column justify-content-center align-items-center">
       <div className="col-md-8">
       <h2 style={{fontWeight:"650"}}>Our Mission</h2>
        <p className="p-4 py-0" style={{fontWeight:"600",fontSize:"13px",color:"white"}}>At Rentout.homes, our primary objective is to simplify the renting experience for our clients. We understand that finding the right place to call home or arranging short-term accommodations can be a time-consuming and complex process. That's where we come in. Our mission is to streamline and expedite the rental process, ensuring a smooth, fast, and accurate solution that ultimately saves our clients both time and money.</p>
       </div>
        <div className="buttonss">
            <button>Post a Property</button>
        </div>
      </div>

      <div className="container my-4 ">
        <h2 style={{fontWeight:"650"}} className="text-center my-4">What Sets Us Apart</h2>
        <div className="container mb-4">
          <h5> Comprehensive Listings</h5>
          <p style={{fontWeight:"600",fontSize:"13px",color:"grey"}} >Explore a vast and carefully curated selection of rental options to suit every need. Whether you're looking for a cozy 1BHK apartment, a shared coliving space, or a spacious 4BHK home, our platform offers a diverse range of choices.</p>
        </div>
        <div className="container mb-4">
          <h5>  Personalized Assistance</h5>
          <p style={{fontWeight:"600",fontSize:"13px",color:"grey"}} >We believe in the power of personalized service. Our dedicated team is committed to understanding your unique requirements and preferences. By doing so, we can provide tailored recommendations that align with your expectations, making your renting journey stress-free.</p>
        </div>
        <div className="container mb-4">
          <h5>Efficiency and Accuracy</h5>
          <p style={{fontWeight:"600",fontSize:"13px",color:"grey"}} >Time is of the essence when it comes to finding the perfect accommodation. Our platform is designed for efficiency, ensuring that you can locate suitable options quickly. We pride ourselves on the accuracy of our listings, giving you confidence in your rental decisions.</p>
        </div>
        <div className="container mb-4">
          <h5>Short-Term Stays Made Easy</h5>
          <p style={{fontWeight:"600",fontSize:"13px",color:"grey"}} >In addition to long-term rentals, we cater to those seeking short-term stays. Explore a variety of guest houses, bed and breakfasts, and hotels, all easily accessible through our platform.</p>
        </div>
      </div>

<div className="d-flex flex-column container justify-content-center align-items-center ">
<div className="row">
<div className="col-md-3 " style={{marginTop:"50px"}}>
       
       <div class=" cardbodyAbout d-flex justify-content-center"  style={{width:"22rem"}}>
   <div class="card-body p-1">
     <h4 class="card-title">User-Friendly Platform:</h4>
     <p class="card-text py-2" style={{fontWeight:"600",color:"gray"}}> Navigate our user-friendly platform with ease. Our intuitive interface allows you to browse through listings effortlessly.</p>
     <div className="buttonssAbout">
            <button>Post a Property</button>
        </div>
   </div>
 </div>
       </div>
       <div className="col-md-3 " style={{marginTop:"50px"}}>
       <div class=" cardbodyAbout"  style={{width:"22rem"}}>
   <div class="card-body ">
     <h4 class="card-title">Customized Searches:</h4>
     <p class="card-text py-2" style={{fontWeight:"600",color:"gray"}}>Utilize our advanced search filters to narrow down options based on your specific requirements, whether it's budget, location, or amenities.</p>
     <div className="buttonssAbout">
            <button>Post a Property</button>
        </div>
   </div>
 </div>
       </div>
       <div className="col-md-3" style={{marginTop:"50px"}}>
       <div class="cardbodyAbout"  style={{width:"22rem"}}>
   <div class="card-body ">
     <h4 class="card-title">Expert Support:</h4>
     <p class="card-text py-2"style={{fontWeight:"600",color:"gray"}}>Our team of experts is available to provide guidance and answer any queries you may have throughout the process.</p>
     <div className="buttonssAbout">
            <button>Post a Property</button>
        </div>
   </div>
 </div>
       </div>
       <div className="col-md-3" style={{marginTop:"50px"}}>
       <div class="cardbodyAbout"  style={{width:"22rem"}}>
   <div class="card-body p-2">
     <h4 class="card-title">Secure Transactions::</h4>
     <p class="card-text py-2" style={{fontWeight:"600",color:"gray"}}>Trust in our secure payment systems, ensuring a safe and reliable transaction process.</p>
     <div className="buttonssAbout">
            <button>Post a Property</button>
        </div>
   </div>
 </div>
       </div>
</div>
</div>
<div className="container my-5">
  <h3 className="py-2">Join Us in Simplifying Your Rental Journey</h3>
  <p style={{fontWeight:"600",fontSize:"16px",color:"grey"}}>At Rentout.Homes, we are committed to revolutionizing the way you approach renting. Say goodbye to the complexities and uncertainties of the rental process. Join us on a journey where finding your ideal home is not just a goal but a seamless reality.

Discover the ease of renting with Rentout.homes. Your perfect home is just a click away!</p>
</div>
    </div>
  );
};

export default Aboutusimage;
