import axios from "axios";
import React, { useEffect, useState, useRef } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Shimmerui from "./Shimmerui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import AOS from "aos";
import Select from "react-select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import $ from 'jquery';
import "aos/dist/aos.css"; // You can also use <link> for styles
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import Belowslider from "./Belowslider";
import Footerslider from "./Footerslider";


// ..
// ..
// ..
// ..
AOS.init();

const Form = ({ google, ...props }) => {


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [videodata, setVideoData] = useState([]);
  const [showText, setShowText] = useState(true);

  const [videoFilterData, setVideoFilterData] = useState([]);
  const [visibleData, setVisibleData] = useState(videoFilterData.slice(0, 15)); // Initial visible data
  const [rotation, setRotation] = useState(0);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(false);

  const [CityData, setCityData] = useState([]);
  const [modalopen, setmodalopen] = useState(false);
  const [selectadedata, setselectadedata] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("4"); // Initialize with an empty string
  const [SelectedAreaId, setSelecteAreaId] = useState(""); // Initialize with an empty string
  const [Areadata, setAreadata] = useState([]);
  const [category, setcategory] = useState([]);
  const [categoryIds, setcategoryIds] = useState([]);
  const [minAmount, setMinAmount] = useState("500");
  const [maxAmount, setMaxAmount] = useState("500000");
  const [stepAmount, setStepAmount] = useState("5000");
  const [propsid, setPropsId] = useState(""); // Initialize with the value of props.selectedId
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [Results, setResults] = useState([]);
  const [howmany, sethowmany] = useState([]);
  const [value, setValue] = React.useState([]);
  const [name, setname] = useState([]);
  const [number, setnumber] = useState([]);
  const [NameOfCategoerys, setNameOfCategoerys] = useState([]);
  const [submitButtonColor, setSubmitButtonColor] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Brokerid, setBrokerid] = useState([]);


  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const offcanvasRef = useRef(null);
  const [directions, setDirections] = useState(null);
  const [TravelTime, settravelTime] = useState(null);

  const [offcanvasClass, setOffcanvasClass] = useState('offcanvas offcanvas-start');

  const navigate = useNavigate();


  const [PerDay, Setperday] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [originalButtonColor, setOriginalButtonColor] = useState("");
  useEffect(() => {
    // Set the original button color when the component mounts
    setOriginalButtonColor(""); // Set your original color here
  }, []);

  console.log("NameOfCategoerys", NameOfCategoerys);

  console.log("Results", Results);

  console.log("videoFilterDatssa", videoFilterData);

  console.log("setSelectedCategoryName", selectedCategoryName);

  console.log("propsid", propsid);

  console.log("maxAmount", maxAmount);


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 600) {
        setOffcanvasClass('offcanvas offcanvas-bottom');
      } else {
        setOffcanvasClass('offcanvas offcanvas-start');
      }
    }

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCityChange = (selectedOption) => {
    setSelectedCityId(selectedOption.value);
  };
  const handleAreaChange = (selectedOptions) => {
    setSelecteAreaId(selectedOptions.map((option) => option.value));
    console.log(
      "areas",
      selectedOptions.map((option) => option.value)
    );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setcategoryIds(selectedOption.value);
      setSelectedCategoryName(selectedOption.label);
    }
  };





  // useEFects // useEFects // useEFects // useEFects // useEFects // useEFects
  // useEFects
  // useEFects
  // useEFects


  const Rotate = () => {
    setRotation(rotation + 360); // Increment rotation by 360 degrees on each click
    clearTimeout()
    setIsFirstDivVisible(!isFirstDivVisible); // Toggle visibility of the first div
  };


  console.log("selectedId --> ", props.selectedId);
  useEffect(() => {
    fetchData("");
    fetchDataCity();
    fetchDataArea();
    fetchcategorys();
    getUserLocation();

    // fetchFillterbySearch();
  }, []);
  console.log("121", categoryIds);
  useEffect(() => {
    // Set propsid when props.selectedId changes
    setPropsId(props.selectedId.id);
    fetchData(props.selectedId.id);
    setSelectedCategoryName("");
  }, [props.selectedId]);

  useEffect(() => {
    // Set categoryIds when props.selectedId changes
    setcategoryIds(props.selectedId.id);

    // Update NameOfCategoerys when the product option changes
  }, [props.selectedId]);





  const fetchData = async (catID) => {

    console.log("catID", catID);

    let id = "";
    
    if (catID === undefined || catID === "12") {

      id = "";
    } else {
      id = catID;
    }

    if (id === "10" || id === "11") {
      Setperday(true);
    } else {
      Setperday(false);
    }

const response ={

    // method: "getData",
    // table: "firms",
    // categoryId: `${id}`,

    method: "getData",
    table: "firms",
    column: "categoryId",
    value: ""
  
}
console.log("responseresponses5",response)

    const data = await axios.post(
      "https://bookmyqrcode.com/jhalamand/api/api.php",
      {
        // method: "getData",
        // table: "firms",
        // categoryId: `${id}`,

        method: "getData",
        table: "firms",
        column: "categoryId",
        value: `${id}`
      }
    );

    setVideoData(data?.data?.data);
    setVideoFilterData(data?.data?.data);
    console.log("ss--->", data);
    setNameOfCategoerys(props.selectedId.name);
    console.log("setVideoFilterData12", data?.data?.data);
  };

  const handleFilter = async () => {
    try {
      // Set propsid to an empty string

      console.log("111", categoryIds);

      setPropsId("");
      if (selectedCategoryName === "") {
        setNameOfCategoerys(props.selectedId.name);
      } else {
        setNameOfCategoerys(selectedCategoryName); // Use selectedCategoryName directly
      }

      // Create the request object
      const request = {
        method: "getDataInventory",
        categoryId: categoryIds === "12" ? "" : `${categoryIds}`,
        areaId: `${SelectedAreaId}`,
        rent: `${value[0]},${value[1]}`,
        // cityId: `${selectedCityId}`,
      };
      console.log("handleFilter", request);
      // Make the API call
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        request
      );
      console.log("selectedCategoryName", selectedCategoryName);

      // Always update the category name, regardless of the API response

      // Check the response status
      if (response.data.status === "Success") {
        // Update state with the received data
        setVideoFilterData(response.data.data);
        sethowmany(response.data.data.length);
      } else if (response.data.status === "false") {
        // Handle the case when no data is found
        sethowmany("No");
        setVideoFilterData([]);
        toast.error("No Data Found", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error--->", error);
      // Handle the error, e.g., show an error message.
    }
  };

  const fetchDataCity = async () => {
    const CityData = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "city",
        column: "status",
        value: "1",
      }
    );

    setCityData(CityData.data.data);
    console.log("DataShows", CityData.data.data);
  };
  const fetchDataArea = async () => {
    const Areadatas = await axios.post(
      "https://bookmyqrcode.com/jhalamand/api/api.php",
      {
        method: "getData",
        table: "area",
        column: "cityId,status",
        value: `${selectedCityId},1`,
        orderColumn: "sort_order",
        orderValue: "asc",
      }
    );

    if (Areadatas.status === "false") {
      alert("no");
    } else {
      setAreadata(Areadatas.data.data);
      console.log("DataShows", Areadatas.data.data);
    }
  };
  const fetchcategorys = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/jhalamand/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const filteredData = data?.data?.data;

    // Remove the first element from the filtered data

    setcategory(filteredData);
    console.log("filteredData", filteredData);
  };


  useEffect(() => {
    // Log the selectedLocation whenever it changes
    console.log("selectedLocation", selectedLocation);
    if (selectedLocation) {
      calculateAndDisplayRoute();
    }
  }, [selectedLocation]);



  useEffect(() => {

    if (videoFilterData.length > 0) {
      fitMapBounds();
    }


  }, [videoFilterData]);


  const fitMapBounds = () => {

    const bounds = new google.maps.LatLngBounds();
    videoFilterData.forEach((location) => {
      bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
      console.log("location.latitude", location.latitude)
      console.log("losdsde", location.latitude)
      console.log("741", videoFilterData)

    });

    const mapInstance = mapRef.current.map;
    mapInstance.fitBounds(bounds);
    console.log("mapInstance", mapRef.current.map)

  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(location);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const openOffcanvas = () => {
    // Use Bootstrap's offcanvas show method
    const offcanvasInstance = new window.bootstrap.Offcanvas(offcanvasRef.current);
    offcanvasInstance.show();
  };

  const calculateAndDisplayRoute = () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    const origin = new google.maps.LatLng(userLocation.lat, userLocation.lng);
    const destination = new google.maps.LatLng(selectedLocation.latitude, selectedLocation.longitude);

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          setDirections(response);
          console.log("response", response);

          const travelTime = response.routes[0].legs[0].duration.text;
          console.log("Travel Time:", travelTime);

          settravelTime(travelTime);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      }
    );
  };


  useEffect(() => {
    fetchDataArea();
  }, [selectedCityId]);

  useEffect(() => {
    if (categoryIds.length > 0) {
      const selectedCategoriesData = category.filter((cat) =>
        categoryIds.includes(cat.categoryId)
      );

      if (selectedCategoriesData.length > 0) {
        const allMaxValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.max_amount)
        );
        const allMinValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.min_amount)
        );

        const maxAmount = Math.max(...allMaxValues);
        const minAmount = Math.min(...allMinValues);

        // Set default values if minAmount is null or empty
        const finalMinAmount = minAmount || 5000;

        // Set default values if maxAmount is null or empty
        const finalMaxAmount = maxAmount || 500000;

        setValue([finalMinAmount, finalMaxAmount]);
        setMaxAmount(finalMaxAmount);
        setMinAmount(finalMinAmount);

        console.log("selectedCategoriesData", selectedCategoriesData);
        console.log("allMinValues", allMinValues);
        console.log("allMaxValues", allMaxValues);
        console.log("minAmount", finalMinAmount);
        console.log("maxAmount", finalMaxAmount);
        console.log("setValue", categoryIds);
      } else {
        // Handle the case when no categories are selected
        setDefaultValues();
      }
    } else {
      setDefaultValues();
    }
  }, [categoryIds, category]);

  const setDefaultValues = () => {
    console.log("No categories selected");
  };

  useEffect(() => {
    // Set the default value to [1] if categoryIds is an empty array
    if (categoryIds.length === 0) {
      setcategoryIds("12");

      console.log("125", categoryIds);
    }
  }, []);

  const SendData = async () => {
    const request = {
      method: "setData",
      table: "enquiry",
      data: {
        name: `${name}`,
        brokerid: `${Brokerid}`,
        number: `${number}`,
        shifting_date: `${selectedDate}`,
        inventroyId: `${selectadedata.inventroyId}`,
      },
    };
    console.log("requestSendDatass", request);

    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    if (number.length < 1) {
      toast.error("Enter Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    //else if (number.length !== 10) {
    //   toast.error("Enter 10 Digit", {
    //     position: "bottom-right",
    //     autoClose: 4988,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    //   return; // Exit the function if any field is empty
    // } 
    else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "setData",
          table: "enquiry",
          data: {
            name: `${name}`,
            brokerid: `${Brokerid}`,
            number: `${number}`,
            shifting_date: `${selectedDate}`,
            inventroyId: `${selectadedata.inventroyId}`,
          },
        }
      );


      $.ajax({
        url: 'https://www.fairpockets.com/ApiIntegration/getdataapi',
        type: 'POST',
        data: {
          apikey: "8880a70789ada62399a7b0d12a774197",
          name: `${name}`,
          mobile: `${number}`,
          email: "", // You may leave it as an empty string if not provided
          project: `${selectadedata.category_name}`,
          source: "4",
          requirement: `<br><b>Inventory Id:</b> ${selectadedata.inventroyId},<br>
     <b>Title:</b> ${selectadedata.title},<br>
       <b>Broker Name:</b> ${selectadedata.broker_name},<br>
   <b>City Name:</b> ${selectadedata.city_name},<br>
 <b>Rent:</b> ${selectadedata.rent},<br>
      <b>Furniture Type:</b> ${selectadedata.furniture_type},<br>
      <b>Amenities:</b> ${selectadedata.admin_remark}`
        },
        dataType: 'json',
        success: function (data) {
          // Handle API response here
          console.log(data);
        },
        error: function (error) {
          // Handle errors here
          console.error('Error:->', error);
        }
      });


      //   const datas = await axios.post(
      //     "https://www.fairpockets.com/ApiIntegration/getdataapi",
      //     {
      //       apikey: "8880a70789ada62399a7b0d12a774197",
      //       name: `${name}`,
      //       mobile: `${number}`,
      //       email: "", // You may leave it as an empty string if not provided
      //       project: `${selectadedata.category_name}` ,
      //       source: "4",
      //       requirement: `<br><b>Inventory Id:</b> ${selectadedata.inventroyId},<br>
      //       <b>Title:</b> ${selectadedata.title},<br>
      //       <b>Area Name:</b> ${selectadedata.area_name},<br>
      //       <b>Broker Name:</b> ${selectadedata.broker_name},<br>
      //       <b>City Name:</b> ${selectadedata.city_name},<br>
      //       <b>Rent:</b> ${selectadedata.rent},<br>
      //       <b>Furniture Type:</b> ${selectadedata.furniture_type},<br>
      //       <b>Amenities:</b> ${selectadedata.admin_remark}`
      // }
      //   )

      //   .catch((err)=>{
      //     console.log('Err Api---->',err)
      //   })

      if (data.data.status === "Success") {
        setSubmitButtonColor("green");
        setFormSubmitted(true);
        toast.success(" Success", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",

        });

        setname("");
        setnumber("");
        setSelectedDate(new Date());
        setTimeout(() => {
          setSubmitButtonColor(originalButtonColor);
          setFormSubmitted(false);
        }, 4000);
      } else {
        alert(false);
      }
    }
  };
  // Re-run the effect when modalopen changes

  // Function to open the modal
  // const openModal = (data) => {
  //   setselectadedata(data);
  //   setmodalopen(true);

  //   window.history.pushState(null, "", window.location.href);
  // };

  const handleModalCloseByButton = () => {
    window.history.back();
    setmodalopen(false);
  };

  // close modal on 'back'
  window.onpopstate = () => {
    window.onpopstate = () => { };
    // window.history.back()
    setmodalopen(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", () => {
      setmodalopen(false);
    });
  });

  const updateVisibleData = () => {
    const newVisibleData = videoFilterData.slice(0, 15);
    setVisibleData(newVisibleData);
  };

  useEffect(() => {
    updateVisibleData();
  }, [videoFilterData, /* other dependencies */]);
  ///////////////
  ///////////////
  const loadMore = () => {
    // Simulate loading more data (you should fetch more data from your API here)
    setTimeout(() => {
      const newData = videoFilterData.slice(
        visibleData.length,
        visibleData.length + 12
      );
      setVisibleData((prevData) => [...prevData, ...newData]);
    }, 1000);

  };

  ///////////////
  useEffect(() => {
    // After 5 seconds, set showText to false to hide the text
    const timer = setTimeout(() => {
      setShowText(false);
    }, 3500);

    // Clear the timer when the component unmounts or when showText changes to false
    return () => clearTimeout(timer);
  }, []);


  if (videodata.length === 0) {
    return <Shimmerui />;
  }
  return (
    <div className="container d-flex justify-content-center  ">



      <div className="row videosform section col-md-12">
        <div className="col-md-3 my-5  " id="MobileForm">
          <div className="formmain col-md-12  d-flex flex-column">
            <div className="p-4 form">
              <div id="mobileinner">
                {/* <div className="container px-2 maindiv ">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  City
                </label>
                <div className="City">
                  <Select
                    className=""
                    id=""
                    placeholder="Select Category"
                    options={CityData.map((City) => ({
                      label: City.name,
                      value: City.cityId,
                    }))}
                    value={
                      selectedCityId
                        ? {
                          label: CityData.find(
                            (city) => city.cityId === selectedCityId
                          )?.name,
                          value: selectedCityId,
                        }
                        : null
                    }
                    onChange={handleCityChange}
                  />
                </div>
              </div> */}

                <div className="container px-2 maindiv">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Category
                  </label>
                  <div className="  cityp ">
                    <div className="">
                      <Select
                        className=""
                        id=""
                        isMulti={false} // Set to false for single selection
                        placeholder="Select Product"
                        options={category.map((name) => ({
                          label: name.name,
                          value: name.categoryId,
                        }))}
                        onChange={handleSelectChange}
                        value={
                          categoryIds
                            ? {
                              label: category.find(
                                (name) => name.categoryId === categoryIds
                              )?.name,
                              value: categoryIds,
                            }
                            : null // Provide null when there is no selection
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="">
                <div className="container px-2 maindiv mt-1">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mb-1"
                  >
                    Area
                  </label>
                  <div className="">
                    <Select
                      className=""
                      isMulti
                      placeholder="Select Areas"
                      options={Areadata.map((areadata) => ({
                        label: areadata.name,
                        value: areadata.areaId,
                      }))}
                      onChange={(selectedOptions) =>
                        handleAreaChange(selectedOptions)
                      }
                      value={Areadata.filter((areadata) =>
                        Array.isArray(SelectedAreaId)
                          ? SelectedAreaId.includes(areadata.areaId)
                          : SelectedAreaId === areadata.areaId
                      ).map((areadata) => ({
                        label: areadata.name,
                        value: areadata.areaId,
                      }))}
                    />
                  </div>
                </div>
                {/* <div className=" col-md-12 px-2 maindiv towmaindiv mt-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mb-1"
                >
                  Budget
                </label>
                <Box className="container ">
                  <Slider
                    className="roodt"
                    getAriaLabel={() => "Temperature range"}
                    defaultValue={30}
                    // getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    value={value}
                    Slider
                    onChange={handleChange}
                    step={stepAmount}
                    marks
                    min={parseFloat(minAmount)}
                    max={parseFloat(maxAmount)}
                  />
                </Box>
                <div
                  className="container-fluid d-flex justify-content-between maxheight"
                  style={{ maxHeight: "15px" }}
                >
                  <div>
                    <p>₹ {value[0]}</p>
                  </div>
                  <div>
                    <p>₹ {value[1]}</p>
                  </div>{" "}
                </div>
              </div> */}
              </div>
              <div className="d-flex justify-content-center align-items-center my-1">
                <div className="buttonss d-flex">
                  <button onClick={handleFilter}>Search</button>
                </div>{" "}
              </div>
            </div>
            <div className="mt-2">
              <Belowslider />
            </div>
          </div>
        </div>


        <div className="col-md-9 " id="mobilediv">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between ">
              <h3 className="text-start Heading mb-0">
                {videoFilterData.length >= 0 ? videoFilterData.length : howmany}{" "}
                Results | For {NameOfCategoerys} Rent
              </h3>
              <div className="d-flex justify-content-center align-items-center">
                {/* <p style={{ fontWeight: "600" }} className="mx-2 pb-0 mb-0">Change Look</p> */}
                <img style={{ transform: `rotateY(${rotation}deg)`, fontWeight: "600", maxHeight: "45px" }} className="rotating-image" onClick={Rotate} src="Assets/image/left-arrows.png" alt="" />
              </div>
            </div>

            <div className="col-4">

              {" "}
              {/* <div className="mb-3">
                <div className="input-group mb-3">
                  <select className="form-select" id="inputGroupSelect01">
                    <option value="" disabled selected>
                      Sort by : Relevance
                    </option>

                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}
            </div>


          </div>


          {videoFilterData.length !== 0 ? (
            <div className="mapMAin">
              <div className={isFirstDivVisible ? "visible  d-flex col-12 videobar justify-content-start p-2 py-1 " : "hidden "}>
                <div className="d-flex col-12 videobar justify-content-start p-2 py-1">
                  <div className="row">
                    <div className="d-flex col-12 videobar container-fluid">
                      <div className="row py-0 mb-0  mt-0">
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={loadMore}
                          hasMore={visibleData.length < videoFilterData.length}
                          loader={<div key={0} style={{ display: "flex", justifyContent: "center" }} >
                            <iframe src="https://lottie.host/embed/c8fb0cb2-26a4-4514-b1e9-97edfb86de38/Y5o8S6w9eZ.json"></iframe>
                          </div>}
                        >
                          <div className='row h-130'>
                            {visibleData.map((item, index) => (
                              <div
                                className="col-xl-4 col-lg-6 col-md-4 col-12 p-2 modalvideo "
                                key={index}
                                onClick={() =>
                                  navigate("/Details", { state: { user: item } })
                                }
                              >
                                <div
                                  className="video-container position-relative d-flex flex-column"

                                >
                                  <img
                                    className="col-12"
                                    src={"https://bookmyqrcode.com/jhalamand/" + item.picture}

                                  />

                                  <div className="p-3 pt-4 aboutownerplace">
                                    <p>Name : {item.name}</p>
                                    <p>Number : {item.mobile_no}</p>
                                    <p>Area : {item.address}</p>
                                    <p>About us : {item.about_firm}</p>
                                    {/* <p>Kilometer :</p> */}
                                    <p>Owner Name :</p>
                                  </div>
                                  {/* <div className="h6-container position-absolute bottom-0 start-0 titelposter d-flex align-items-center justify-content-center">
                                    <p
                                      style={{ fontSize: "12px" }}
                                      className="p-0 m-0  mb-0 text-start p-1 videosTitleid "
                                    >
                                      {item.inventroyId}
                                    </p>
                                    <h6 className="videosTitle mb-0 p-2 ">
                                      {item.title}
                                    </h6>
                                  </div> */}
                                </div>
                              </div>
                            ))}
                          </div>
                        </InfiniteScroll>

                      </div>
                    </div>
                  </div>
                </div>  </div>

              <div>


                <div className={!isFirstDivVisible ? "visible col-md-12" : "hidden"}>
                  <div className={`${offcanvasClass} `} data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel"
                    ref={offcanvasRef}>
                    <div class="offcanvas-header">
                      <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body p-0">
                      {selectedLocation ? (
                        <div >
                          <div style={{ height: "250px", maxWidth: "100%" }} className='mb-2' >
                            <img style={{ height: "250px", maxWidth: "100%", minWidth: "100%" }} src="https://img.freepik.com/free-photo/luxury-house-real-estate-sale-property-generative-ai_169016-29361.jpg" alt="" />
                          </div>

                          {directions && (
                            <div className='p-3 pt-1 '>
                              <h5 style={{ fontWeight: "600", fontSize: "16px" }}>{selectedLocation.name}</h5>
                              <hr />
                              <div style={{ fontSize: '14px' }} className="px-3">
                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/map.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{selectedLocation.address != "" ? selectedLocation.address : "Not available now"}</p>
                                </div>

                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/gmail.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{selectedLocation.email != "" ? selectedLocation.email : "Not available now"}</p>
                                </div>
                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/precision.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{`Selected Location: ${TravelTime}`}</p>
                                </div>

                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/distance.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{`Distance: ${directions.routes[0].legs[0].distance.text}`}</p>
                                </div>

                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/phone-call (1).png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{selectedLocation.mobile_no != "" ? selectedLocation.mobile_no : "Not available now"}</p>
                                </div>
                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/telephone-call.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{selectedLocation.mobile_no2 != "" ? selectedLocation.mobile_no2 : "Not available now"}</p>
                                </div>

                                <div className="d-flex  justify-content-start align-items-center my-5">
                                  <img src="Assets/image/internet.png" alt="" />
                                  <p className="pb-0 mb-0 mx-2">{selectedLocation.website != "" ? selectedLocation.website : "Not available now"}</p>
                                </div>


                              </div>
                            </div>


                          )}
                        </div>
                      ) : (
                        <p>Try scrolling the rest of the page to see this option in action.</p>
                      )}
                    </div>
                  </div>

                  <div className=" mapcontainer">
                    {showText && (
                      <iframe className="mapcontaineriframe" src="https://lottie.host/embed/34d9e044-3457-4710-b738-23410782865e/VQfZyxwiTG.json"></iframe>
                    )}
                    <Map
                      google={google}
                      ref={mapRef}
                      style={{ position: "relative", width: "100%", height: "100%" }}
                    >
                      {videoFilterData.map((location) => (
                        <Marker
                          key={location.id}
                          title={location.title}
                          position={{ lat: location.latitude, lng: location.longitude }}
                          onClick={() => {
                            setSelectedLocation(location);
                            openOffcanvas(); // Open offcanvas on marker click

                          }}
                          icon={{
                            url: 'Assets/image/pin.png',
                            anchor: new google.maps.Point(20, 40),
                            scaledSize: new google.maps.Size(30, 30),
                          }}
                        />
                      ))}
                      {userLocation && (
                        <Marker
                          title="Your Location"
                          position={userLocation}
                          icon={{
                            url: 'Assets/image/location.png',
                            anchor: new google.maps.Point(50, 40),
                            scaledSize: new google.maps.Size(50, 50),
                          }}
                        />
                      )}
                      {directions && (
                        <Polyline
                          path={directions.routes[0].overview_path}
                          options={{
                            strokeColor: 'darkblue',
                            strokeWeight: 6,
                            strokeOpacity: 0.7,
                          }}
                        />
                      )}
                    </Map>
                  </div>

                </div>
              </div>
            </div>


          ) : (
            <div
              className="col-md-12 text-center d-flex justify-content-center p-4"
              style={{ width: "100%" }}
            >
              <div className="shimmer-container">
                <dotlottie-player
                  className="lootie"
                  src="https://lottie.host/ce954cf3-ee28-4c87-8f12-73b69d1e2d95/5G93PDLU0Z.json"
                  style={{ maxwidth: "450px", height: "450px" }}
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></dotlottie-player>
                <div className="shimmer"></div>
              </div>
            </div>
          )}

          <div className="my-2">
            <Footerslider />
          </div>

          <div className="buttonss d-flex justify-content-center my-4 ">
            <button hidden={videoFilterData.length >= 0 ? true : false}>
              Load More
            </button>
          </div>
          <ToastContainer style={{ fontSize: "14px", zIndex: "9999999" }}
          />
        </div>


      </div>
    </div>
  );
};


export default GoogleApiWrapper({
  apiKey: 'AIzaSyCeVmtHvHA8qXWLzXguVwlw2JNn6W07U8A',
})(Form);
