// Example of Redux store configuration
import { createStore } from 'redux';

const initialState = {
  brokerId: null,
};



const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BROKER_ID':
      return { ...state, brokerId: action.payload };
    default:
      return state;
  }
};

const store = createStore(rootReducer);

store.subscribe(() => {
  console.log("Updated Broker ID:", store.getState().brokerId);
});

console.log("Broker IDsss:", store.getState().brokerId);


export default store;
