import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { maxHeight } from "@mui/system";
import { useSelector } from 'react-redux';

const Postcontent = () => {
  const [category, setcategory] = useState([]);
  const [name, setname] = useState([]);
  const [rent, setrent] = useState([]);
  const [number, setnumber] = useState([]);
  const [address, setaddress] = useState([]);
  const [zipcode, setzipcode] = useState([]);
  const [categoryId, setCategoryId] = useState("0");
  const [buttonanimation, setbuttonanimation] = useState(true);
  const [nameofanimation, setnameofanimation] = useState(false);

  const brokerId = useSelector(state => state.brokerId);
  
console.log("sdsds",brokerId)
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const removeAllFromlist = data.data.data;
    removeAllFromlist.shift();
    setcategory(removeAllFromlist);
    console.log("data", data);
  };

  const fetchdatas = async () => {
    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }

    if (number.length < 1) {
      toast.error("Enter Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    else if (number.length !== 10) {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }


    if (categoryId.length === 0) {
      toast.error("Select Property Type", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }

    if (rent.length === 0) {
      toast.error("Select rent", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }

    if (address.length === 0) {
      toast.error("Select Address", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    if (zipcode.length === 0) {
      toast.error("Select Zipcode", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else {
      const request = {
        method: "setData",
        table: "post_rent",
        data: {
          name: `${name}`,
          number: `${number}`,
          categoryId: `${categoryId}`,
          rent: `${rent}`,
          address: `${address}`,
          zip_code: `${zipcode}`,
          brokerID: `${brokerId}`,

          image_url:
            "https://rentalhomes-01.web.app/Assets/image/Group%20170.png",
          video_url: "",
        },
      };
 setname("")
setrent("")
setnumber("")
 setaddress("")
setzipcode("")
 setCategoryId("")

      console.log("requestaa", request);
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "setData",
          table: "post_rent",
          data: {
            name: `${name}`,
            number: `${number}`,
            categoryId: `${categoryId}`,
            rent: `${rent}`,
            address: `${address}`,
            zip_code: `${zipcode}`,
            brokerID: `${brokerId}`,
            image_url:
              "https://rentalhomes-01.web.app/Assets/image/Group%20170.png",
            video_url: "",
          },
        }
      );

      setbuttonanimation(false);
      setnameofanimation(true);

      setTimeout(() => {
        // Hide the animation and show the text again
        setbuttonanimation(true);
        setnameofanimation(false);
      }, 1700);

      console.log("succ", data.data.status)
      console.log("data", data);
      if (data.data.status === "Success") {
        toast.success(" Success", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        alert(false);
      }
    }
  };

  return (
    <div className="container-fluid ">
      <div className="container  " id="Forms">
        <div>
          <div className="d-flex justify-content-between align-items-start p-1 pb-0">
            <div>
              <h4 style={{ fontWeight: "600" }}>Your Inventorys</h4>
              <h6 className="pt-2">Fill The Form to Proceed and Sell or Rent Your Property</h6>
            </div>

            <div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-md-4 p-1">
              {" "}
              <div class="mb-3">
                <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 p-1">
              {" "}
              <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                Number
              </label>
              <input
                type="number"
                class="form-control"
                placeholder="Enter your mobile number"
                id="exampleInputPassword1"
                value={number}
                onChange={(j) => {
                  const limitedNumbers = j.target.value.slice(0, 10);  // Limit to 10 characters
                  setnumber(limitedNumbers);
                }}
               
              />
            </div>

            <div className="col-md-4 TypeofProperty p-1">
              {" "}
              <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                Type of Property
              </label>
              <select
                style={{ width: "95%" }}
                id="propertyType"
                className="form-select"
                aria-label="Default select example"
                value={categoryId} // Use categoryId here
                placeholder="Select Category"
                onChange={(e) => setCategoryId(e.target.value)}
              >
                {category.map((s) => (
                  <option key={s.id} value={s.categoryId}>
                    {""}
                    {/* Use category ID as the value */}
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 p-1 pt-0">
              {" "}
              <div class="mb-3">
                <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                  Rent Expected
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Expected Rent"
                  id="exampleInputPassword1"
                  value={rent}
                  onChange={(r) => {
                    setrent(r.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 p-1 py-0">
              {" "}
              <div class="mb-3">
                <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                  Address
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your address here"
                  id="exampleInputPassword1"
                  value={address}
                  onChange={(a) => {
                    setaddress(a.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 p-1 py-0">
              {" "}
              <div class="mb-3">
                <label htmlhtmlFor="exampleInputPassword1" class="form-label">
                  Zipcode
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter your zipcode"
                  id="exampleInputPassword1"
                  value={zipcode}
                  onChange={(s) => {
                    const limitedNumber = s.target.value.slice(0, 6);  // Limit to 10 characters
                    setzipcode(limitedNumber);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="buttonss d-flex justify-content-center">
            <button

              hidden={nameofanimation}
              onClick={() => {
                fetchdatas();
              }}
            >

              <p className="mb-0" >Post Now</p>
              <div>
              </div>
            </button>
            <ToastContainer />
          </div>
          <div className="d-flex justify-content-center animatonbutton">
            <iframe style={{ maxHeight: "45px", maxWidth: "120px" }} className="pb-0 mb-0 " src="https://lottie.host/embed/3e63ad60-7223-47b0-a95d-2c75bc08e019/oMWrGa17jl.json" hidden={buttonanimation} ></iframe>
          </div>
        </div>
      </div>



    </div>
  );
};

export default Postcontent;
