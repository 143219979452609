import Aboutus from "./Component/Aboutus";
import Homepage from "./Component/Homepage";
import Post from "./Component/Post";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Loginpage from "./Component/Loginpage";
import Broker from "./Component/Broker";

import { Provider } from 'react-redux';

import store from "./Component/Store";
import MapContainer from "./Component/MapContainer";
import Details from "./Component/Details";
import Belowslider from "./Component/Belowslider";

function App() {


  return (
    <Provider store={store}>

<BrowserRouter>
      <Routes>
      
        <Route path="/" element={<Homepage />} />
        <Route path="/post" element={<Post />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/Loginpage" element={<Loginpage />} />
        <Route path="/Broker" element={<Broker />} />
        <Route path="/MapContainer" element={<MapContainer />} />
        <Route path="/Details" element={<Details />} />
        <Route path="/Belowslider" element={<Belowslider />} />
       
        {/* The "index" route should be the last */}
        <Route index element={<Homepage />} />
      </Routes>
    </BrowserRouter>
    </Provider>

  );
}

export default App;
