import React, { useEffect ,useState } from 'react'
import axios from "axios";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
const Footerslider = () => {

    const [footerslider, setproduct2] = useState([])

useEffect(() =>{
    Sliders()
},[])

    const Sliders = async () => {
        try {
          const response = await axios.post("https://bookmyqrcode.com/jhalamand/api/api.php", {
            "method": "getData",
            "table": "sliders",
            "column": "status",
            "value": "1"
          });
      
          console.log("dataSlider", response.data.data);
      
          // Filter sliders with slider_type "home"
          const footer = response.data.data.filter(group => group.slider_type === "footer");


          setproduct2(footer);
        } catch (error) {
          console.error("Error fetching sliders:", error);
        }
      };
  return (
    <div>
     

          <div className="" >
        <div id="carouselExample2" className="carousel slide">
        <div className="carousel-inner" style={{borderRadius:"5px"}}> 
            {footerslider.map((img, index) => (
              <div
               style={{height:"300px"}}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <img
                class="sliderimg"
             
                  src={img.slider}
                  alt={`Slide ${index}`}
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample2"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample2"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>



       
      </div>
    </div>
  )
}

export default Footerslider;
