import React from "react";
import Aboutushome from "./Aboutushome";
import Header from "./Header";
import Aboutusimage from "./Aboutusimage";
import Footer from "./Footer";
const Aboutus = () => {
  return (
    <div>
      <Header />
      <Aboutushome />
      <Aboutusimage/>
      <Footer/>
    </div>
  );
};

export default Aboutus;
